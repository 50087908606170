import { extendTheme } from "@chakra-ui/react"

const Button = {
  baseStyle: {
    _hover: { boxShadow: "none", bg: "transparent" },
    _active: { boxShadow: "none", bg: "transparent" },
    _expanded: { boxShadow: "none", bg: "transparent" },
    _focus: { boxShadow: "none", bg: "transparent" },
  },
}

const Link = {
  baseStyle: {
    boxShadow: "none",
    _active: { boxShadow: "none" },
    _focus: { boxShadow: "none" },
    _hover: { textDecoration: "none" },
  },
}

const Accordion = {
  parts: ["button"],
  baseStyle: {
    button: {
      _hover: { boxShadow: "none", bg: "transparent" },
      _active: { boxShadow: "none" },
      _expanded: { boxShadow: "none" },
      _focus: { boxShadow: "none" },
    },
  },
}

const Select = {
  parts: ["field", "icon"],
  baseStyle: {
    field: {},
    icon: {
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
  defaultProps: {
    variant: "",
  },
}

const theme = {
  fonts: {
    body: "Roboto",
  },
  colors: {
    background: "#FFFFFF",
  },
  components: {
    Button,
    Link,
    Accordion,
    Select,
  },
}

export default extendTheme(theme)
