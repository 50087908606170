exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-best-surgeons-and-hospitals-research-all-stars-js": () => import("./../../../src/pages/best-surgeons-and-hospitals-research-all-stars.js" /* webpackChunkName: "component---src-pages-best-surgeons-and-hospitals-research-all-stars-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bpci-advanced-js": () => import("./../../../src/pages/bpci-advanced.js" /* webpackChunkName: "component---src-pages-bpci-advanced-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-caremeasurement-js": () => import("./../../../src/pages/caremeasurement.js" /* webpackChunkName: "component---src-pages-caremeasurement-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-eras-js": () => import("./../../../src/pages/eras.js" /* webpackChunkName: "component---src-pages-eras-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-pacu-and-or-throughput-js": () => import("./../../../src/pages/pacu-and-or-throughput.js" /* webpackChunkName: "component---src-pages-pacu-and-or-throughput-js" */),
  "component---src-pages-pre-launch-research-all-stars-faq-js": () => import("./../../../src/pages/pre-launch-research-all-stars-FAQ.js" /* webpackChunkName: "component---src-pages-pre-launch-research-all-stars-faq-js" */),
  "component---src-pages-pre-launch-research-all-stars-js": () => import("./../../../src/pages/pre-launch-research-all-stars.js" /* webpackChunkName: "component---src-pages-pre-launch-research-all-stars-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-all-stars-badges-js": () => import("./../../../src/pages/research-all-stars-badges.js" /* webpackChunkName: "component---src-pages-research-all-stars-badges-js" */),
  "component---src-pages-research-all-stars-faq-js": () => import("./../../../src/pages/research-all-stars-FAQ.js" /* webpackChunkName: "component---src-pages-research-all-stars-faq-js" */),
  "component---src-pages-research-all-stars-key-findings-js": () => import("./../../../src/pages/research-all-stars-key-findings.js" /* webpackChunkName: "component---src-pages-research-all-stars-key-findings-js" */),
  "component---src-pages-research-all-stars-methodology-js": () => import("./../../../src/pages/research-all-stars-methodology.js" /* webpackChunkName: "component---src-pages-research-all-stars-methodology-js" */),
  "component---src-pages-research-all-stars-press-release-template-js": () => import("./../../../src/pages/research-all-stars-press-release-template.js" /* webpackChunkName: "component---src-pages-research-all-stars-press-release-template-js" */),
  "component---src-pages-research-all-stars-social-media-templates-js": () => import("./../../../src/pages/research-all-stars-social-media-templates.js" /* webpackChunkName: "component---src-pages-research-all-stars-social-media-templates-js" */),
  "component---src-pages-surgical-coding-optimization-js": () => import("./../../../src/pages/surgical-coding-optimization.js" /* webpackChunkName: "component---src-pages-surgical-coding-optimization-js" */),
  "component---src-pages-vbhc-research-group-js": () => import("./../../../src/pages/vbhc-research-group.js" /* webpackChunkName: "component---src-pages-vbhc-research-group-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-careers-post-js": () => import("./../../../src/templates/careers-post.js" /* webpackChunkName: "component---src-templates-careers-post-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-ranking-page-js": () => import("./../../../src/templates/ranking-page.js" /* webpackChunkName: "component---src-templates-ranking-page-js" */),
  "component---src-templates-splash-page-js": () => import("./../../../src/templates/splash-page.js" /* webpackChunkName: "component---src-templates-splash-page-js" */)
}

